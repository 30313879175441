import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'

const Col = styled.div`
  width: 22%;
  text-align: left;

  .icon-container {
    height: 53px;
    display: block;
  }

  h5 {
    font-family: Raleway;
    font-size: 18px;
    font-weight: 500;
    font-style: normal;
    line-height: 28px;
    letter-spacing: 0.0727273px;
    color: ${props => props.theme.colors.tertiary};
    margin-bottom: 2em;
    margin-top: 2em;
  }

  p {
    font-family: Raleway;
    font-size: 16px;
    font-weight: 300;
    font-style: normal;
    line-height: 140%;
    letter-spacing: 0.0456169px;
  }

  @media screen and (max-width: 600px) {
    width: 100%;

    .step-number {
      margin-bottom: 2.5em;
    }

    .step-number hr {
      display: none;
    }

    h5 {
      margin-bottom: 1.5em;
      margin-top: 1.5em;
      font-weight: 300;
    }

    p {
      margin-bottom: 5em;
    }
  }

`

const Row = styled.section`
  display: flex;
  justify-content: space-between;
  margin-bottom: 4em;

  @media screen and (max-width: 600px) {
    flex-direction: column;
    // margin-top: 5em;
    margin-bottom: 0em;
  }
`

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  padding-top: ${props => (props.paddingTop ? props.paddingTop : '6em')};
  align-items: center;
  padding-bottom: ${props => (props.paddingBottom ? props.paddingBottom : '4em')};

  @media screen and (max-width: ${props => props.theme.breakpoint.medium}px) {
    padding-left: 3.5em;
    padding-right: 3.5em;
  }
`

const Icon = styled(Img)`
  max-height: 53px;
  max-width: 60px;
  top: 100%;
  transform: translateY(-100%);
  div {
    height: 100%;
    width: auto;
  }
  & img {
    object-fit: contain !important;
  }
`

const BenefitsSection = props => {
  return (
    <Wrapper paddingTop={props.paddingTop}>
      <Row>
        {props.benefits && props.benefits.map((benefit, index) => (
          <Col key={`benefit-${index}`}>
            <div className="icon-container">
              <Icon fluid={benefit.fluid} />
            </div>
            <h5>{benefit.title}</h5>
            <p>
              {benefit.description}
            </p>
          </Col>
        ))}
      </Row>
    </Wrapper>
  )
}

export default BenefitsSection
