import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import styled from 'styled-components'
import config from '../utils/siteConfig'
import Layout from '../components/Layout'
import Title from '../components/Title'
import Container from '../components/Container'
import DescriptionSection from '../components/DescriptionSection'
import StepSection from '../components/StepSection'
import WeWorkWithSection from '../components/WeWorkWithSection'
import GetQuoteSection from '../components/GetQuoteSection'
import GridInfluencers from '../components/GridInfluencers'
import GridInfluencerCard from '../components/GridInfluencerCard'
import BenefitsSection from '../components/BenefitsSection'
import HeroIndex from '../components/HeroIndex'
import SEO from '../components/SEO'

/* const Title = styled.h1`
  width: 100%;
  padding-top: 1em;
  font-family: Raleway;
  font-style: normal;
  font-weight: 300;
  line-height: 125%;
  font-size: 24px;
  text-align: center;
  letter-spacing: 0.177083px;
  color: ${props => props.theme.colors.tertiary};
  @media screen and (max-width: 600px) {
    line-height: 25px;
    font-size: 18px;
    letter-spacing: 0.43px;
  }
` */

const Wrapper = styled.section`
  text-align: center;
  max-width: ${props => props.theme.sizes.maxWidthCentered};
  margin: auto;
`

const PageTemplate = ({ data }) => {
  const content = data.contentfulContentPage
  const galleryImages =
    content.middleGallery.length > 6
      ? content.middleGallery.slice(0, 4)
      : content.middleGallery
  const steps = content.steps ? content.steps.list 
      ? content.steps.list : []
      : []  

  let layoutRef = React.createRef();

  const handleOnClickSignUp = () => {
    if (layoutRef && 
      layoutRef.current &&
      layoutRef.current.menuContainerRef) {
      
      const menuContainerRef = layoutRef.current.menuContainerRef;
      if (menuContainerRef.current && 
        menuContainerRef.current.menuRef && 
        menuContainerRef.current.menuRef.current &&
        menuContainerRef.current.menuRef.current.openInfluencerRegisterModal
      ) {
        menuContainerRef.current.menuRef.current.openInfluencerRegisterModal();
      }
    }
  }

  return (
    <Layout ref={layoutRef}>
      <Helmet>
        <title>{`${config.siteTitle}`}</title>
      </Helmet>
      <SEO pagePath={content.slug} postNode={content} pageSEO />

      <HeroIndex
        subtitle={content.heroTitle}
        image={content.heroImage}
        isBlue
        footer={content.heroImageFooter}
        position={'top center'}
        onPressButton={ () => handleOnClickSignUp() }
        fixed
      />

      {/* <Wrapper>
        <Title paddingTop="1.5em" title={content.heroImageTitle} big/>
      </Wrapper> */}

      <Container verticalPadding={false}>
        <DescriptionSection fontWeight="300" message={content.description} />
        <Title className="subtitle" title={content.subtitle} />
        <StepSection linkTitle="Influencer FAQ" steps={steps} linkTo="https://join.embello.com/influencer-faq/"/>
      </Container>

      <Title paddingTop="9em" paddingBottom="7em" className="subtitle" title={content.middleTitle} />
      
      <WeWorkWithSection />

      <GridInfluencers
        columnNumber={3}
        marginTop={'0'}
      >
        {galleryImages.map((image, index) => (
          <div key={`galleryForInfluencers${index}`}>
            <GridInfluencerCard
              image={image}
              footer={image.title}
              columnNumber={3}
              index={index}
            />
          </div>
        ))}
      </GridInfluencers>

      {content.slug === 'brands' ? (
        <div />
      ) : (
        <GetQuoteSection 
          consultationTitle={content.boxTitle}
          consultationSubtitle={content.boxContent}
          linkTitle="JOIN NOW"
          linkTo="/signup/iform"
        />
      )}
      <Container padding={'3.5em 1.5em 2em'}>
        <Title paddingTop="2em" paddingBottom="3.5em" className="subtitle" title={content.benefitsTitle} />
        <BenefitsSection benefits={content.benefits}/>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    contentfulContentPage(slug: { eq: $slug }) {
      title
      subtitle
      steps {
        list {
          title
          description
        }
      }
      description
      benefitsTitle
      benefits {
        title
        description
        fluid(maxWidth: 1800) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      slug
      heroImageMobile {
        title
        fixed(width: 1800) {
          ...GatsbyContentfulFixed_withWebp_noBase64
        }
      }
      heroTitle
      heroImage {
        title
        fluid(maxWidth: 1800) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      heroImageTitle
      heroImageFooter {
        childMarkdownRemark {
          html
          excerpt(pruneLength: 320)
        }
      }
      metaDescription {
        internal {
          content
        }
      }
      middleTitle
      middleGallery {
        id
        title
        fluid(maxWidth: 1800, maxHeight: 1200) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      statsTitle
      boxTitle
      boxContent {
        childMarkdownRemark {
          html
          excerpt(pruneLength: 320)
        }
      }
      boxButton
      boxImage {
        fluid(maxWidth: 1800, maxHeight: 1200) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      boxTitle
      boxContent {
        internal {
          content
        }
      }
      boxButton
    }
  }
`

export default PageTemplate
